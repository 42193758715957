import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Celebrate from '../components/Celebrate';
import StoreInfoCard from '../components/StoreInfoCard';
import Testimonials from '../components/Testimonials';

export default function Home() {
  return (
    <Layout>
      <Hero />
      <Celebrate />
      <StoreInfoCard />
      <Testimonials />
    </Layout>
  );
}
